import cx from 'clsx';
import PropTypes from 'prop-types';

import Badge from '@placekit/uikit/components/Badge';
import Icon from '@placekit/uikit/components/Icon';

const FeaturesGrid = (props) => {
  return (
    <div className={props.className}>
      <dl
        className={cx(
          'mx-auto max-sm:max-w-sm',
          'grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 lg:gap-8',
          'text-base leading-7',
          {
            'text-gray-600': props.theme === 'light',
            'text-accent-200': props.theme === 'accent',
          },
        )}
      >
        {props.features.map((feature) => (
          <div key={feature.title} className="relative pl-9">
            <dt
              className={cx('inline-flex items-baseline gap-1 font-semibold', {
                'text-gray-900': props.theme === 'light',
                'text-white': props.theme === 'accent',
              })}
            >
              <Icon
                name={feature.icon}
                className={cx('absolute left-1 top-1', {
                  'text-gray-600': props.theme === 'light',
                  'text-accent-100': props.theme === 'accent',
                })}
              />
              {feature.soon && <Badge kind="primary" label="soon" className="self-center" />}
              <span>{feature.title}.</span>
            </dt>{' '}
            <dd className="inline">{feature.description}</dd>
          </div>
        ))}
      </dl>
    </div>
  );
};

FeaturesGrid.defaultProps = {
  features: [],
  theme: 'light',
};

FeaturesGrid.propTypes = {
  theme: PropTypes.oneOf(['light', 'accent']),
  features: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.node,
      icon: PropTypes.string,
      soon: PropTypes.bool,
    }),
  ),
  className: PropTypes.string,
};

export default FeaturesGrid;
