import { Transition } from '@headlessui/react';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

import Dot from '@placekit/uikit/components/Dot';

import { useRandomRadial } from 'features/useRandomRadial';

const randomInt = (min, max) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1) + min);
};

const Point = () => {
  const [show, setShow] = useState(false);
  const [pos, generate] = useRandomRadial({
    angleMin: 10,
    angleMax: 170,
    distanceMin: 0.72,
    distanceMax: 0.98,
  });

  useEffect(() => {
    const timeout = setTimeout(
      () => {
        setShow((prev) => !prev);
      },
      randomInt(3, 9) * 1000,
    );
    return () => {
      clearInterval(timeout);
    };
  }, [show]);

  return (
    <Transition
      appear={true}
      show={show}
      enter="transition-opacity duration-1000"
      enterFrom="opacity-0"
      enterTo="opacity-100"
      leave="transition-opacity duration-1000"
      leaveFrom="opacity-100"
      leaveTo="opacity-0"
      beforeEnter={generate}
      aria-hidden={true}
    >
      <Dot
        className="absolute z-1 -translate-x-1/2 -translate-y-1/2"
        style={{
          top: pos.top,
          left: pos.left,
        }}
      />
    </Transition>
  );
};

const HeroAnimation = (props) => {
  return (
    <div className={props.className} role="img" aria-hidden={true}>
      <div className="relative w-full p-4 select-none pointer-events-none">
        <div className="relative w-full aspect-square">
          <svg
            viewBox="0 0 1026 1026"
            fill="none"
            strokeLinecap="round"
            className="absolute inset-0 w-full h-full stroke-1 stroke-gray-300/50 animate-[spin_3s_linear_infinite]"
          >
            <circle cx="513" cy="513" r="512" />
            <path stroke="url(#hero-1)" d="M513 1025C230.23 1025 1 795.77 1 513" />
            <defs>
              <linearGradient
                id="hero-1"
                x1="913"
                y1="513"
                x2="913"
                y2="913"
                gradientUnits="userSpaceOnUse"
              >
                <stop offset="0" stopColor="#7cadfd" />
                <stop offset="1" stopColor="#7cadfd" stopOpacity="0" />
              </linearGradient>
            </defs>
          </svg>
          <svg
            viewBox="0 0 1026 1026"
            fill="none"
            strokeLinecap="round"
            className="absolute inset-0 w-full h-full stroke-1 stroke-gray-300/50 animate-[spin_6s_linear_infinite_reverse]"
          >
            <circle cx="513" cy="513" r="400" />
            <path stroke="url(#hero-2)" d="M913 513c0 220.914-179.086 400-400 400" />
            <defs>
              <linearGradient
                id="hero-2"
                x1="1"
                y1="513"
                x2="1"
                y2="1025"
                gradientUnits="userSpaceOnUse"
              >
                <stop offset="0" stopColor="#7cadfd" />
                <stop offset="1" stopColor="#7cadfd" stopOpacity="0" />
              </linearGradient>
            </defs>
          </svg>
        </div>
      </div>
      {Array.from(Array(6).keys()).map((i) => (
        <Point key={i.toString()} />
      ))}
    </div>
  );
};

HeroAnimation.propTypes = {
  className: PropTypes.string,
};

export default HeroAnimation;
