import placekit from '@placekit/client-js';
import cx from 'clsx';
import dynamic from 'next/dynamic';
import PropTypes from 'prop-types';
import { useCallback, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { debounce } from 'throttle-debounce';
import validator from 'validator';

import ActionGroup from '@placekit/uikit/components/ActionGroup';
import Button from '@placekit/uikit/components/Button';
import Form from '@placekit/uikit/components/Form';
import FormField from '@placekit/uikit/components/FormField';
import Icon from '@placekit/uikit/components/Icon';
import Input from '@placekit/uikit/components/Input';

import { useUIContext } from 'features/UIContext';
import { useIPCoordinates } from 'features/useIPCoordinates';

const Map = dynamic(() => import('components/Map'), {
  ssr: false,
  loading: () => <div className="w-full h-full bg-gray-50" />,
});

const pk = placekit(process.env.NEXT_PUBLIC_PLACEKIT_API_KEY);

const DemoReverse = (props) => {
  const { notify } = useUIContext();
  const [geolocation, setGeolocation] = useState();
  const [coords, setCoords] = useIPCoordinates('');
  const [address, setAddress] = useState();

  const methods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
  });

  const onGeolocation = useCallback(() => {
    if (pk.hasGeolocation) {
      pk.clearGeolocation();
      setGeolocation();
    } else {
      pk.requestGeolocation()
        .then((pos) => {
          const coordinates = [pos.coords.latitude, pos.coords.longitude].join(',');
          setCoords(coordinates);
          setGeolocation(coordinates);
        })
        .catch((error) => {
          notify({
            kind: 'danger',
            icon: 'exclamation-triangle',
            delay: 3000,
            title: 'Geolocation request failed',
            message: error.message,
          });
        });
    }
  }, [notify, setCoords]);

  const search = useMemo(
    () =>
      debounce(100, (opts) => {
        pk.reverse(opts).then(({ results }) => setAddress(results?.[0]));
      }),
    [],
  );

  const updateCoords = useCallback(
    (coordinates, updateInput = true) => {
      setCoords(coordinates);
      if (updateInput) {
        methods.setValue('coordinates', coordinates);
      }
      if (coordinates) {
        search({ coordinates: coordinates, types: ['street'], maxResults: 1 });
      }
    },
    [methods, search, setCoords],
  );

  return (
    <div className={cx('relative', props.className)}>
      <Form
        methods={methods}
        onSubmit={(values) => updateCoords(values.coordinates, false)}
        className="absolute top-4 left-4 right-16 z-2"
      >
        <div className="max-w-md flex flex-col rounded-md bg-gray-50 shadow-md shadow-gray-800/5">
          <ActionGroup stack={false} fullWidth={true}>
            <Button
              kind={geolocation ? 'secondary' : 'neutral'}
              label="Toggle geolocation"
              icon="map-pin"
              iconOnly={true}
              iconKind="outline"
              onClick={onGeolocation}
              className="!grow-0"
            />
            <FormField
              name="coordinates"
              label="Coordinates"
              discrete={true}
              placeholder="Type coordinates or move the map..."
              rules={{
                validate: (val) => validator.isLatLong(val?.trim()),
              }}
              render={(fieldProps) => <Input {...fieldProps} />}
            />
            <Button
              type="submit"
              label="Submit"
              kind="secondary"
              icon="arrow-long-right"
              iconOnly={true}
              className="!grow-0"
            />
          </ActionGroup>
          <p className="px-2 py-1 inline-flex items-top gap-1 text-xs text-gray-700">
            <Icon name="exclamation-circle" size="small" />
            <span>Coordinates are approximated to the middle of the streets.</span>
          </p>
        </div>
      </Form>
      {!!address && (
        <aside className="absolute bottom-8 left-4 max-sm:right-4 z-1 max-sm:text-center">
          <address className="inline-flex px-2 py-1 rounded bg-gray-900/70 text-white text-xs md:text-sm not-italic max-w-full">
            {address.name}
            <br />
            {[address.zipcode[0], address.city, address.countrycode?.toUpperCase()].join(' ')}
          </address>
        </aside>
      )}
      <Map
        className="w-full h-full"
        zoom={16}
        scrollWheelZoom={false}
        coordinates={coords}
        onCoords={updateCoords}
      />
    </div>
  );
};

DemoReverse.propTypes = {
  className: PropTypes.string,
};

export default DemoReverse;
