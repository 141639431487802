import { PlaceKit } from '@placekit/autocomplete-react';
import cx from 'clsx';
import dynamic from 'next/dynamic';
import PropTypes from 'prop-types';
import { useCallback, useState } from 'react';

import { useUIContext } from 'features/UIContext';
import { useIPCoordinates } from 'features/useIPCoordinates';

const Map = dynamic(() => import('components/MapLibre'), {
  ssr: false,
  loading: () => <div className="w-full h-full bg-gray-50" />,
});

const DemoGeocoding = (props) => {
  const { notify } = useUIContext();
  const [coords, setCoords] = useIPCoordinates();
  const [coordsDisplay, setCoordsDisplay] = useIPCoordinates();
  const [zoom, setZoom] = useState(12);

  const onGeolocation = useCallback(
    (isGeolocationEnabled, pos, error) => {
      if (error) {
        notify({
          kind: 'danger',
          icon: 'exclamation-triangle',
          delay: 3000,
          title: 'Geolocation request failed',
          message: error,
        });
      } else if (isGeolocationEnabled) {
        setZoom(18);
        setCoords([pos.coords.latitude, pos.coords.longitude].join(','));
        setCoordsDisplay([pos.coords.latitude, pos.coords.longitude].join(','));
      }
    },
    [notify, setCoords, setCoordsDisplay],
  );

  const onPick = useCallback(
    (value, item) => {
      switch (item.type) {
        case 'bus':
        case 'street':
        case 'tourism':
        case 'townhall':
        case 'train':
          setZoom(16);
          break;
        case 'airport':
          setZoom(14);
          break;
        case 'city':
          setZoom(13);
          break;
        case 'county':
          setZoom(8);
          break;
        case 'administrative':
          setZoom(6);
          break;
        case 'country':
          setZoom(3);
          break;
        default:
          setZoom(12);
          break;
      }
      setCoords(item.coordinates);
      setCoordsDisplay(item.coordinates);
    },
    [setCoords, setCoordsDisplay, setZoom],
  );

  return (
    <div className={cx('relative', props.className)}>
      <div className="absolute top-4 left-4 right-16 z-2">
        <PlaceKit
          name="address"
          placeholder="Search for an address..."
          className="max-w-md w-full"
          apiKey={process.env.NEXT_PUBLIC_PLACEKIT_API_KEY}
          onPick={onPick}
          onGeolocation={onGeolocation}
        />
      </div>
      {!!coordsDisplay && (
        <aside className="absolute bottom-8 left-4 max-sm:right-4 z-1 max-sm:text-center">
          <p className="inline-flex px-2 py-1 rounded bg-gray-900/70 text-white text-xs md:text-sm max-w-full">
            {coordsDisplay}
          </p>
        </aside>
      )}
      <Map
        className="w-full h-full"
        zoom={zoom}
        onZoom={setZoom}
        lat={parseFloat(coords.split(',')[0])}
        lng={parseFloat(coords.split(',')[1])}
        onCoords={setCoords}
        onCoordsDisplay={setCoordsDisplay}
      />
    </div>
  );
};

DemoGeocoding.propTypes = {
  className: PropTypes.string,
};

export default DemoGeocoding;
