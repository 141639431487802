import { PlaceKit } from '@placekit/autocomplete-react';
import cx from 'clsx';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
dayjs.extend(localizedFormat);

import ActionGroup from '@placekit/uikit/components/ActionGroup';
import Button from '@placekit/uikit/components/Button';
import DateTime from '@placekit/uikit/components/DateTime';
import Form from '@placekit/uikit/components/Form';
import FormField from '@placekit/uikit/components/FormField';
import Icon from '@placekit/uikit/components/Icon';
import Select from '@placekit/uikit/components/Select';
import Spinner from '@placekit/uikit/components/Spinner';

import { useUIContext } from 'features/UIContext';

const DemoTravel = (props) => {
  const { notify } = useUIContext();
  const [values, setValues] = useState();
  const [booked, setBooked] = useState();
  const [showResults, setShowResults] = useState(false);

  const methods = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      city: '',
      country: '',
      countrycode: '',
      start: dayjs().add(1, 'weeks').day(6).format('YYYY-MM-DD'),
      end: dayjs().add(2, 'weeks').day(6).format('YYYY-MM-DD'),
      people: '2',
    },
  });

  useEffect(() => {
    setShowResults(false);
    setBooked(false);
    if (values) {
      const timeout = setTimeout(() => {
        setShowResults(true);
      }, 1000);
      return () => clearTimeout(timeout);
    }
  }, [values]);

  const onPick = useCallback(
    (_, item) => {
      methods.setValue('city', item?.city ?? '', { shouldValidate: true });
      methods.setValue('country', item?.country ?? '', { shouldValidate: true });
      methods.setValue('countrycode', item?.countrycode ?? '', { shouldValidate: true });
    },
    [methods],
  );

  const onGeolocation = useCallback(
    (value, pos, error) => {
      if (error) {
        notify({
          kind: 'danger',
          icon: 'exclamation-triangle',
          delay: 3000,
          title: 'Geolocation request failed',
          message: error,
        });
      }
    },
    [notify],
  );

  return (
    <div className={props.className}>
      <p className="mb-2 px-2 font-semibold text-sm text-gray-600">
        Make a beautiful global city and country search:
      </p>
      <Form methods={methods} onSubmit={setValues} className="flex flex-col gap-4">
        <ActionGroup stack={false} fullWidth={true}>
          <FormField
            name="country"
            label="Country"
            discrete={true}
            rules={{
              required: true,
            }}
            render={() => (
              <PlaceKit
                name="destination"
                id="destination"
                placeholder="Traveling to..."
                apiKey={process.env.NEXT_PUBLIC_PLACEKIT_API_KEY}
                options={{
                  types: ['city', 'country'],
                  countrySelect: false,
                  maxResults: 10,
                }}
                geolocation={false}
                onPick={onPick}
                onEmpty={onPick}
                onGeolocation={onGeolocation}
                className="grow w-full !rounded-r-none"
              />
            )}
          />
          <Button
            type="submit"
            kind="secondary"
            label="Search"
            disabled={!methods.formState.isValid}
            className="!grow-0"
          />
        </ActionGroup>
        <ActionGroup stack={true} fullWidth={true}>
          <FormField
            name="start"
            label="Outbound date"
            discrete={true}
            controlled={true}
            render={({ errored, ...fieldProps }) => <DateTime {...fieldProps} />}
          />
          <FormField
            name="end"
            label="Return date"
            discrete={true}
            controlled={true}
            render={({ errored, ...fieldProps }) => <DateTime {...fieldProps} />}
          />
          <FormField
            name="people"
            id="people"
            label="People"
            discrete={true}
            render={({ errored, className, ...fieldProps }) => (
              <div className="grow flex">
                <Select
                  {...fieldProps}
                  grouped={true}
                  options={[
                    { value: '1', label: '1' },
                    { value: '2', label: '2' },
                    { value: '3', label: '3' },
                    { value: '4', label: '4' },
                  ]}
                  className="grow relative focus:z-1 rounded-bl-md @sm:rounded-none"
                />
                <label
                  htmlFor="people"
                  className={cx(
                    'shrink-0 inline-flex items-center justify-center w-10',
                    'border border-l-0 border-gray-300 bg-gray-50',
                    'rounded-br-md @sm:rounded-r-md',
                    'text-gray-500',
                  )}
                >
                  <Icon name="users" size="small" />
                </label>
              </div>
            )}
          />
        </ActionGroup>
      </Form>
      {!!values &&
        (!showResults ? (
          <aside className="py-8 text-center text-accent-500">
            <Spinner size="medium" />
          </aside>
        ) : (
          <section className="flex flex-col gap-4 p-4 mt-6 border border-gray-200 rounded-lg bg-white shadow-md shadow-gray-800/5">
            <article className="flex max-sm:flex-col sm:justify-between sm:items-center gap-4">
              <div className="flex flex-col gap-4">
                <header className="flex items-center gap-3">
                  {/* eslint-disable @next/next/no-img-element */}
                  <img
                    src={`https://flagcdn.com/64x48/${values.countrycode?.toLowerCase()}.png`}
                    alt={values.country}
                    loading="lazy"
                    className="block h-[1em] w-auto"
                  />
                  <h3 className="text-lg font-medium text-accent-600">
                    {[values.city, values.country].filter(Boolean).join(', ')}
                  </h3>
                </header>
                <dl className="flex flex-col gap-1 text-sm">
                  <div className="flex items-center gap-2 text-sm">
                    <dt className="inline-flex items-center gap-1 text-gray-600">
                      <Icon name="calendar" className="text-gray-400" /> Dates:
                    </dt>
                    <dd>
                      <strong className="font-medium text-gray-800">
                        {dayjs(values.start).format('ll')}
                      </strong>
                      {' to '}
                      <strong className="font-medium text-gray-800">
                        {dayjs(values.end).format('ll')}
                      </strong>
                    </dd>
                  </div>
                  <div className="flex items-center gap-2 text-sm">
                    <dt className="inline-flex items-center gap-1 text-gray-600">
                      <Icon name="users" className="text-gray-400" /> Travelers:
                    </dt>
                    <dd className="font-medium text-gray-800">{values.people}</dd>
                  </div>
                </dl>
              </div>
              <Button
                label="Book"
                kind={booked ? 'neutral' : 'secondary'}
                icon={booked ? 'check-circle' : 'arrow-long-right'}
                iconPosition="right"
                iconKind="outline"
                rounded={false}
                disabled={booked}
                onClick={() => setBooked(true)}
              />
            </article>
          </section>
        ))}
    </div>
  );
};

DemoTravel.propTypes = {
  className: PropTypes.string,
};

export default DemoTravel;
