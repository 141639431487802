import placekit from '@placekit/client-js';
import { useEffect, useState } from 'react';

const pk = placekit(process.env.NEXT_PUBLIC_PLACEKIT_API_KEY);

const storageKey = 'ip-based-coordinates';
export const useIPCoordinates = () => {
  const [coords, setCoords] = useState('');

  useEffect(() => {
    if (typeof window === 'undefined') return;
    const coordinates = sessionStorage.getItem(storageKey);
    if (coordinates) {
      setCoords(coordinates);
    } else {
      pk.reverse().then(({ results }) => {
        sessionStorage.setItem(storageKey, results?.[0]?.coordinates);
        setCoords(results?.[0]?.coordinates);
      });
    }
  }, []);

  return [coords, setCoords];
};
