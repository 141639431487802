import cx from 'clsx';
import PropTypes from 'prop-types';

import Badge from '@placekit/uikit/components/Badge';
import Icon from '@placekit/uikit/components/Icon';

const FeaturesList = (props) => (
  <div className={props.className}>
    <header className="flex items-center gap-2">
      <h3 className="text-xl font-bold text-gray-900 tracking-tight sm:text-3xl">{props.title}</h3>
      {props.soon && <Badge kind="primary" size="large" label="beta" />}
    </header>
    <p className="mt-3 text-lg text-gray-500">{props.description}</p>
    <dl className="mt-10 space-y-10">
      {props.features.map((item, i) => (
        <div key={i.toString()} className="relative">
          <dt>
            <div
              className={cx('absolute flex items-center justify-center h-12 w-12 rounded-md', {
                'bg-accent-500 text-white': !item.soon && !props.soon,
                'bg-accent-100 text-accent-800': item.soon || props.soon,
              })}
            >
              <Icon name={item.icon} size="medium" aria-hidden="true" />
            </div>
            <p className="ml-16 inline-flex items-center gap-2 text-lg leading-6 font-medium text-gray-900">
              {item.title}
              {!props.soon && item.soon && <Badge kind="primary" label="beta" />}
            </p>
          </dt>
          <dd className="mt-2 ml-16 text-base text-gray-500">{item.description}</dd>
        </div>
      ))}
    </dl>
    {props.children}
  </div>
);

FeaturesList.defaultProps = {
  soon: false,
  features: [],
};

FeaturesList.propTypes = {
  title: PropTypes.string,
  description: PropTypes.node,
  soon: PropTypes.bool,
  features: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      soon: PropTypes.bool,
      description: PropTypes.node,
      icon: PropTypes.string,
    }),
  ),
  className: PropTypes.string,
  children: PropTypes.node,
};

export default FeaturesList;
