import { useEffect } from 'react';

export const useFetchIntercept = (callback) => {
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const { fetch: originalFetch } = window;
      window.fetch = async (...args) => {
        const res = await originalFetch(...args);
        callback(args[0]?.toString(), args[1], res.clone());
        return res;
      };

      return () => {
        window.fetch = originalFetch;
      };
    }
  }, [callback]);
};
