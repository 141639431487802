import cx from 'clsx';
import Image from 'next/image';
import PropTypes from 'prop-types';

import Button from '@placekit/uikit/components/Button';
import Carousel from '@placekit/uikit/components/Carousel';

import FeaturesGrid from 'components/FeaturesGrid';
import FeaturesList from 'components/FeaturesList';
import Section from 'components/Section';
import SnippetsAPI from 'components/SnippetsAPI';
import StoreLocatorAnimation from 'components/StoreLocatorAnimation';
import Subscribe from 'components/Subscribe';

const Features = (props) => (
  <div className={cx('divide-y divide-gray-200', props.className)}>
    <Section id="overview" theme="gradient-accent">
      <Section.Header title="Better UX, better conversion" center={true}>
        <p className="text-lg text-accent-100">
          A few keystrokes for your users, better addresses for your business. Optimize checkouts,
          reduce misshipments, search places on maps, and cover even more use-cases.
        </p>
      </Section.Header>
      <FeaturesGrid
        theme="accent"
        features={[
          {
            title: 'Great developer experience',
            description:
              'Autocomplete, live patching, store locator and two-way geocoding all served by a single headache-free API.',
            icon: 'cube',
          },
          {
            title: 'Transparent predictable pricing',
            description:
              'Pay per request, per month, with volume discount. First 10k/month requests are on us!',
            icon: 'cube-transparent',
          },
          {
            title: 'Fully managed and fault tolerant',
            description:
              'Enterprise-grade infrastructure distributed over multiple datacenters to provide 100% uptime.',
            icon: 'server-stack',
          },
          {
            title: 'Powered by Algolia',
            description: 'Instant response times, typo-tolerant and high-relevance search.',
            icon: 'bolt',
          },
          {
            title: 'Quality data',
            description:
              'Hand-curated data from OpenStreetMap combined with country-specific datasets.',
            icon: 'eye-dropper',
          },
          {
            title: 'Regular updates',
            description: 'We refresh and update our data regularly to provide accurate coverage.',
            icon: 'arrow-path',
          },
          {
            title: 'No vendor lock-in',
            description: 'Our terms of service allow you to use our data with other services.',
            icon: 'lock-open',
          },
          {
            title: 'Global coverage',
            description: 'Support for 246 countries over 249 from the ISO-3166-1 list.',
            icon: 'globe-europe-africa',
          },
          {
            title: 'Languages',
            description: `Search and show results in your users' language, automatically detected.`,
            icon: 'language',
          },
          {
            title: 'Data privacy',
            description:
              'We are GDPR compliant. We will never sell, share or trade your users data with third parties.',
            icon: 'shield-check',
          },
          {
            title: 'Friendly support',
            description: 'We want to provide the service we would like to be customers of.',
            icon: 'face-smile',
          },
        ]}
      />
      <footer className="flex max-sm:flex-col items-center justify-center gap-y-4 sm:gap-y-0 sm:gap-x-6">
        <Button
          href="https://app.placekit.io/auth/register"
          label="Get started for free"
          kind="secondary"
          size="medium"
        />
        <Button
          href="/#features"
          label="Learn more"
          kind="none"
          size="medium"
          icon="arrow-down-circle"
          iconPosition="right"
          iconKind="outline"
          className="bg-accent-700 shadow-sm text-white hover:bg-accent-800 focus:bg-accent-800"
        />
      </footer>
    </Section>
    <Section id="features">
      <h2 className="sr-only">Features</h2>
      <div className="space-y-16 lg:space-y-32">
        <article
          id="autocomplete"
          className="flex flex-col md:flex-row justify-between items-center md:items-start gap-12 md:gap-8"
        >
          <FeaturesList
            title="Autocomplete"
            description="Build a pleasant, fast and automatic address lookup experience for your mobile app and website in seconds."
            className="shrink-0 md:basis-1/2"
            features={[
              {
                title: 'State-of-the-art',
                description: `
            Our autocomplete is typo-tolerant and provides relevant suggestions at each keystroke, highlighting matches and showcasing result types.
            Lightweight, cross-browser, and accessible by design.
          `,
                icon: 'sparkles',
              },
              {
                title: 'Convenience and customization',
                description: `
            Import our autocomplete package, insert your credentials. 
            That's all it takes. Easily customize style and events, or create from scratch with our zero-dependency JavaScript client that integrates with any other autocomplete component.
          `,
                icon: 'paint-brush',
              },
              {
                title: 'Device location',
                description: `
            Ask users to share their device location to greatly improve the relevance by suggesting places closer to them.
            Defaults to their IP location.
          `,
                icon: 'map-pin',
              },
            ]}
          >
            <footer className="mt-10 flex flex-wrap items-center justify-center md:justify-start md:pl-16 gap-4">
              <Button
                href="/developers"
                label="Autocomplete SDKs"
                kind="neutral"
                size="medium"
                icon="cube"
                iconPosition="right"
                iconKind="outline"
              />
              <Button
                href="/developers#examples"
                scroll={false}
                label="Code examples"
                kind="neutral"
                size="medium"
                icon="code-bracket-square"
                iconPosition="right"
                iconKind="outline"
              />
            </footer>
          </FeaturesList>
          <aside className="md:basis-1/2 w-full max-w-lg md:mt-14">
            <Carousel
              autoplay={6000}
              className="aspect-square bg-gray-100 shadow-xl shadow-gray-800/5"
            >
              {[
                { src: '/media/autocomplete-checkout.png', alt: 'Checkout Autocomplete example' },
                { src: '/media/autocomplete-map.png', alt: 'Map Autocomplete example' },
                {
                  src: '/media/autocomplete-stores.png',
                  alt: 'Store Locator Autocomplete example',
                },
              ].map((img, i) => (
                <Image key={i.toString()} src={img.src} alt={img.alt} width={1020} height={1020} />
              ))}
            </Carousel>
          </aside>
        </article>
        <article id="api" className="grid md:grid-cols-2 gap-12 md:gap-8 items-start">
          <FeaturesList
            title="Geocoding API"
            description="A single API to cover all use-cases, with easy-to-use and quality tools for developers."
            className="min-w-0 md:order-last"
            features={[
              {
                title: 'Geocoding REST API',
                description: `
            PlaceKit is first and foremost a geocoding service. 
            Send geocoding and reverse-geocoding requests from the front-end or the back-end to meet any of your use-cases.
          `,
                icon: 'arrow-path',
              },
              {
                title: 'Developers first',
                description: `
            Our JavaScript libraries are well packaged with clear documentation, types, and examples, providing easy-to-use abstractions to PlaceKit services. 
            We also provide an annotated API reference to use PlaceKit with other languages.
          `,
                icon: 'code-bracket',
              },
              {
                title: 'Data quality',
                description: `
            We use fresh data from OpenStreetMap, supercharged with country-specific datasets when available, and meticulously sanitized to shrink it all into the most comprehensive catalog.
          `,
                icon: 'globe-europe-africa',
              },
              {
                title: 'Live patching',
                description: `Add missing addresses or fix existing ones, and make them instantly available to your users. We then review and merge them back to our global dataset, because you know best.`,
                icon: 'scissors',
              },
            ]}
          >
            <footer className="mt-10 flex flex-wrap items-center justify-center md:justify-start md:pl-16 gap-4">
              <Button
                label="API reference"
                href="https://api.placekit.io"
                target="blank"
                kind="neutral"
                size="medium"
                icon="arrow-top-right-on-square"
                iconPosition="right"
                iconKind="outline"
              />
              <Button
                label="Coverage"
                href="/terms/coverage"
                kind="neutral"
                size="medium"
                icon="globe-alt"
                iconPosition="right"
                iconKind="outline"
              />
            </footer>
          </FeaturesList>
          <aside className="min-w-0 md:mt-14 flex items-start justify-center md:justify-start">
            <SnippetsAPI className="!max-w-lg w-full shadow-xl shadow-gray-800/5" />
          </aside>
        </article>
        <article
          id="store-locator"
          className="flex flex-col md:flex-row justify-between items-center md:items-start gap-12 md:gap-8"
        >
          <FeaturesList
            title="Store locator"
            description="Help your customers find the closest point of interest to their location, showing them results on a map or as a list as they type their address."
            soon={true}
            className="shrink-0 md:basis-1/2"
            features={[
              {
                title: 'Unlimited locations',
                description: `Set your points of interest directly from our dashboard without limit, or use our zero-dependency JavaScript client to automate it from the back-end.`,
                icon: 'server-stack',
              },
              {
                title: 'Free-form metadata',
                description: `Define any location metadata you need: business hours, reviews, etc. using your own schema, and our API will return them as-is.`,
                icon: 'puzzle-piece',
              },
              {
                title: 'Map integration',
                description: `PlaceKit integrates with any map provider thanks to our geocoding data. We provide integration examples to help you get started.`,
                icon: 'map',
              },
            ]}
          />
          <aside className="md:basis-1/2 w-full max-w-lg">
            <StoreLocatorAnimation />
          </aside>
        </article>
      </div>
      <footer className="text-center">
        <Subscribe
          list="store-locator"
          title="Be notified for the Store Locator release!"
          className=""
        />
      </footer>
    </Section>
  </div>
);

Features.propTypes = {
  className: PropTypes.string,
};

export default Features;
