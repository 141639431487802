import { PlaceKit } from '@placekit/autocomplete-react';
import cx from 'clsx';
import PropTypes from 'prop-types';
import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';

import Form from '@placekit/uikit/components/Form';
import FormField from '@placekit/uikit/components/FormField';
import Input from '@placekit/uikit/components/Input';

import { useUIContext } from 'features/UIContext';
import { Button } from '@placekit/uikit';

const DemoCheckout = (props) => {
  const { notify } = useUIContext();
  const [address, setAddress] = useState();

  const methods = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
  });

  const onReset = useCallback(() => {
    setAddress();
    methods.reset();
  }, [methods]);

  const onPick = useCallback(
    (_, item) => {
      methods.setValue('line1', item.name);
      methods.setValue('city', item.city);
      methods.setValue('administrative', item.administrative);
      if (item.zipcode[0]) {
        methods.setValue('zipcode', item.zipcode[0]);
      }
      methods.setValue('country', item.country);
    },
    [methods],
  );

  const onGeolocation = useCallback(
    (value, pos, error) => {
      if (error) {
        notify({
          kind: 'danger',
          icon: 'exclamation-triangle',
          delay: 3000,
          title: 'Geolocation request failed',
          message: error,
        });
      }
    },
    [notify],
  );

  return (
    <div className={cx('grid lg:grid-cols-3 gap-4 lg:gap-6', props.className)}>
      <p className="lg:order-last max-lg:px-4 lg:pt-6 font-semibold text-sm text-gray-600">
        Automatically fill address forms in your checkout funnel to increase conversion and reduce
        shipping errors!
      </p>
      <div className="lg:col-span-2 p-4 sm:p-6 rounded-lg bg-white border border-gray-200 shadow-md shadow-gray-800/5">
        <div
          className={cx('grid grid-cols-2 gap-4', {
            hidden: !address,
          })}
        >
          <h3 className="col-span-full font-medium text-gray-600">Shipping address</h3>
          <address className="col-span-full flex flex-col pl-4 border-l-2 border-gray-100 text-sm not-italic">
            <span className="font-medium">{address?.line1}</span>
            <span>
              {address?.zipcode} {address?.city}
            </span>
            <span>{address?.administrative}</span>
            <span className="uppercase">{address?.country}</span>
          </address>
          <Button onClick={() => setAddress()} rounded={false} label="Edit shipping" />
          <Button
            onClick={onReset}
            kind="secondary"
            rounded={false}
            label="Payment"
            icon="arrow-long-right"
            iconPosition="right"
          />
        </div>
        <Form
          methods={methods}
          onSubmit={setAddress}
          className={cx('grid grid-cols-2 gap-4 items-end', {
            hidden: !!address,
          })}
        >
          <FormField
            name="line1"
            label="Address"
            className="col-span-full"
            render={() => (
              <PlaceKit
                name="line1"
                placeholder="Start typing an address..."
                className="w-full"
                apiKey={process.env.NEXT_PUBLIC_PLACEKIT_API_KEY}
                options={{
                  types: ['street'],
                }}
                onPick={onPick}
                onGeolocation={onGeolocation}
              />
            )}
          />
          <FormField
            name="city"
            label="City"
            className="col-span-full"
            render={({ errored, ...fieldProps }) => <Input {...fieldProps} />}
          />
          <FormField
            name="administrative"
            label="State/province"
            className="col-span-full"
            render={({ errored, ...fieldProps }) => <Input {...fieldProps} />}
          />
          <FormField
            name="zipcode"
            label="Post code"
            render={({ errored, ...fieldProps }) => <Input {...fieldProps} />}
          />
          <FormField
            name="country"
            label="Country"
            render={({ errored, ...fieldProps }) => <Input {...fieldProps} />}
          />
          <Button
            type="submit"
            kind="secondary"
            rounded={false}
            disabled={!methods.watch('line1')}
            label="Save shipping address"
            className="col-span-full"
          />
        </Form>
      </div>
    </div>
  );
};

DemoCheckout.propTypes = {
  className: PropTypes.string,
};

export default DemoCheckout;
