import Button from '@placekit/uikit/components/Button';
import Icon from '@placekit/uikit/components/Icon';

import PricingCalculator from 'components/PricingCalculator';
import Section from 'components/Section';

const benefits = [
  {
    icon: 'arrow-trending-up',
    title: 'Pay-as-you-go',
    description: `
      Access all features for free, first 10k requests per month are on us.
      No credit card required.
    `,
  },
  {
    icon: 'receipt-percent',
    title: 'Volume discounts',
    description: `
      We offer a fair volume-based discount policy. 
      Contact us to commit and save even more.
    `,
  },
  {
    icon: 'cube-transparent',
    title: 'Transparent & predictable',
    description: `
      Accurately forecast your expenses with our simple per-request pricing and usage dashboard.
    `,
  },
];

const Pricing = () => (
  <Section
    id="pricing"
    theme="gradient-accent"
    className="!border-y !border-accent-400"
    title="Pricing"
    description
  >
    <Section.Header size="medium" center={true} title="Pricing">
      <p className="mt-6 font-bold text-lg tracking-wide text-accent-100">
        Pay per request, per month. Period.
      </p>
      <p className="tracking-wide text-accent-200">No limitations, no hidden costs.</p>
    </Section.Header>

    <PricingCalculator className="my-16 shadow-lg" />

    <dl className="space-y-10 lg:space-y-0 lg:grid lg:grid-cols-3 lg:gap-8">
      {benefits.map((item, i) => (
        <div key={i.toString()} className="relative">
          <dt>
            <div className="absolute lg:static flex items-center justify-center h-12 w-12 rounded-md bg-accent-600">
              <Icon name={item.icon} size="medium" aria-hidden="true" />
            </div>
            <p className="mt-5 ml-16 lg:ml-0 text-lg leading-6 font-medium text-white">
              {item.title}
            </p>
          </dt>
          <dd className="mt-2 ml-16 lg:ml-0 text-base text-accent-200">{item.description}</dd>
        </div>
      ))}
    </dl>

    <div className="mt-16 flex flex-col sm:flex-row items-center justify-center gap-y-4 sm:gap-y-0 sm:gap-x-6">
      <Button
        href="https://app.placekit.io/auth/register"
        label="Get started for free"
        kind="secondary"
        size="medium"
      />
      <Button
        href="/about#contact"
        scroll={false}
        label="Contact us"
        kind="none"
        size="medium"
        className="bg-accent-700 shadow-sm text-white hover:bg-accent-800 focus:bg-accent-800"
      />
    </div>
  </Section>
);

export default Pricing;
