import cx from 'clsx';
import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import { debounce } from 'throttle-debounce';

import '@placekit/autocomplete-js/dist/placekit-autocomplete.css';

import Button from '@placekit/uikit/components/Button';
import Code from '@placekit/uikit/components/Code';
import Dialog from '@placekit/uikit/components/Dialog';
import Dot from '@placekit/uikit/components/Dot';
import Icon from '@placekit/uikit/components/Icon';
import PillsNav from '@placekit/uikit/components/PillsNav';

import Container from 'components/Container';
import DemoAutocomplete from 'components/DemoAutocomplete';
import DemoCheckout from 'components/DemoCheckout';
import DemoGeocoding from 'components/DemoGeocoding';
import DemoReverse from 'components/DemoReverse';
import DemoTravel from 'components/DemoTravel';
import { useFetchIntercept } from 'features/useFetchIntercept';

const HeroDemos = (props) => {
  const [hint, setHint] = useState(0); // 0: untouched, 1: call made, 2: response open
  const [current, setCurrent] = useState('autocomplete');
  const [dialogOpen, setDialogOpen] = useState(false);
  const [payloads, setPayloads] = useState();

  useEffect(() => {
    setHint((prev) => Math.min(prev, 1));
    setPayloads();
  }, [current]);

  useFetchIntercept(
    debounce(100, (url, params, res) => {
      if (url?.includes('https://api.placekit.co')) {
        res.json().then((json) => {
          setHint(1);
          setPayloads({
            request: `
  curl -l -X POST '${url.toString()}' \\
  -H 'x-placekit-api-key: <your-api-key>' \\
  -H 'Content-Type: application/json' \\
  -d '${params.body}'
  `.trim(),
            response: JSON.stringify(json, null, 2),
          });
        });
      }
    }),
  );

  const demoLinks = useMemo(
    () =>
      [
        {
          name: 'autocomplete',
          label: 'Address autocomplete',
          icon: 'magnifying-glass-circle',
        },
        {
          name: 'checkout',
          label: 'E-commerce fast checkout',
          icon: 'shopping-bag',
        },
        {
          name: 'travel',
          label: 'Travel city search',
          icon: 'building-library',
        },
        {
          name: 'geocoding',
          label: 'Map address → coordinates',
          icon: 'map-pin',
        },
        {
          name: 'reverse',
          label: 'Map coordinates → address',
          icon: 'globe-alt',
        },
        {
          name: 'stores',
          label: 'Store locator (beta)',
          icon: 'building-storefront',
          disabled: true,
        },
      ].map(({ name, ...linkProps }) => ({
        ...linkProps,
        onClick: () => setCurrent(name),
        current: current === name,
      })),
    [current],
  );

  return (
    <section className={cx(props.className)}>
      <h2 className="sr-only">Try PlaceKit demos</h2>
      <Container className="relative -top-1 sm:-top-7 z-5 max-sm:!px-0">
        <span
          role="img"
          aria-hidden={true}
          className={cx(
            'absolute -top-8 left-4 md:left-1/3 lg:left-1/4 select-none pointer-events-none',
            {
              'animate-bounce text-accent-600': !hint,
              'text-gray-400': hint,
            },
          )}
        >
          <Dot size="small" show={!hint}>
            <span className="-rotate-6 pr-2 flex items-center gap-1 font-semibold text-sm">
              Try it!
              <Icon name="arrow-uturn-right" size="small" className="rotate-90" />
            </span>
          </Dot>
        </span>
        <div className="relative w-full md:grid md:grid-cols-3 lg:grid-cols-4 max-md:divide-y md:divide-x divide-gray-200 overflow-hidden sm:rounded-lg max-sm:border-t border-gray-200 bg-white shadow-2xl shadow-accent-900/30">
          <div className="px-4 py-2 md:py-4 bg-gray-800 dark">
            <PillsNav icons={true} items={demoLinks} />
          </div>
          <div
            className={cx(
              'md:col-span-2 lg:col-span-3',
              'min-h-80 md:min-h-96 flex items-center justify-center',
              'bg-gradient-to-b from-gray-50 to-gray-100',
              {
                'px-4 pt-12 pb-16': !['geocoding', 'reverse', 'stores'].includes(current),
              },
            )}
          >
            {current === 'autocomplete' && <DemoAutocomplete className="max-w-lg w-full" />}
            {current === 'checkout' && <DemoCheckout className="max-w-sm lg:max-w-2xl w-full" />}
            {current === 'travel' && <DemoTravel className="max-w-xl w-full" />}
            {current === 'geocoding' && <DemoGeocoding className="w-full h-80 md:h-96" />}
            {current === 'reverse' && <DemoReverse className="w-full h-80 md:h-96" />}
          </div>
        </div>
        <footer className="relative -translate-y-1/2 z-2 flex justify-center">
          <Dot show={hint === 1 && !!payloads} size="medium">
            <Button
              kind="neutral"
              size="medium"
              label="View API response"
              icon="command-line"
              iconKind="outline"
              rounded={false}
              onClick={() => {
                setHint(2);
                setDialogOpen(true);
              }}
            />
          </Dot>
        </footer>
      </Container>
      <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <section className="relative max-w-xl w-full m-auto bg-white rounded-lg shadow-xl overflow-hidden">
          <Button
            label="Close"
            kind="none"
            onClick={() => setDialogOpen(false)}
            size="medium"
            icon="x-circle"
            iconPosition="right"
            iconOnly={true}
            className="absolute top-px right-1 z-10 text-gray-200 hover:text-white focus:!outline-none !ring-0 !ring-offset-0"
          />
          <Code rounded={false} maxLines={14}>
            <Code.Tab
              language="json"
              title="JSON response"
              code={
                payloads?.response ?? `// Make a request first! JSON response will appear here...`
              }
              clipboard={true}
            />
            <Code.Tab
              language="sh"
              title="cURL request"
              code={payloads?.request ?? `# Make a request first! cURL request will appear here...`}
              clipboard={true}
              wrap={true}
            />
          </Code>
        </section>
      </Dialog>
    </section>
  );
};

HeroDemos.propTypes = {
  className: PropTypes.string,
};

export default HeroDemos;
