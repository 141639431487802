import PropTypes from 'prop-types';

import Code from '@placekit/uikit/components/Code';

const SnippetsAPI = (props) => (
  <Code maxLines={17} sync={true} className={props.className}>
    <Code.Tab
      language="js"
      clipboard={true}
      title="Node.js"
      code={`
const placekit = require('@placekit/client-js');

const pk = placekit('<your-api-key>', {
  countries: ['fr'],
});

// Geocoding
pk.search('42 avenue Champs-Élysées Paris')
  .then(console.log);
/* JSON response: {[disclose]
  "results": [[disclose]
    {
      "name": "42 Avenue des Champs Élysées",
      "highlight": "<mark>42 Avenue</mark> des <mark>Champs Élysées</mark>",
      "city": "Paris 8e Arrondissement",
      "county": "Paris",
      "administrative": "Île-de-France",
      "country": "France",
      "countrycode": "fr",
      "zipcode": [
        "75008"
      ],
      "population": 2220445,
      "lat": 48.871086,
      "lng": 2.3036339,
      "type": "street"
    },
    //...
  [/disclose]],
  "resultsCount": 2,
  "maxResults": 5,
  "query": "42 avenue Champs-Élysées Paris",
[/disclose]} */

// Reverse geocoding
pk.reverse({ coordinates: '48.8701466,2.3055238' })
  .then(console.log);
/* JSON response: {[disclose]
  "results": [[disclose]
    {
      "name": "Paris 8e Arrondissement",
      "highlight": "Paris 8e Arrondissement",
      "city": "Paris",
      "county": "Paris",
      "administrative": "Île-de-France",
      "country": "France",
      "countrycode": "fr",
      "zipcode": [
        "75008"
      ],
      "population": 2220445,
      "lat": 48.877762,
      "lng": 2.3175571,
      "type": "city"
    },
    //...
  [/disclose]],
  "resultsCount": 5,
  "maxResults": 5,
[/disclose]} */
    `}
    />
    <Code.Tab
      language="sh"
      clipboard={true}
      title="cURL"
      code={`
# Geocoding
curl -l -X POST 'https://api.placekit.co/search' \\
  -H 'x-placekit-api-key: <your-api-key>' \\
  -H 'Content-Type: application/json' \\
  -d '{"query": "42 avenue Champs-Élysées Paris", "countries": ["fr"]}'

# JSON response
{[disclose]
  "results": [[disclose]
    {
      "name": "42 Avenue des Champs Élysées",
      "highlight": "<mark>42 Avenue</mark> des <mark>Champs Élysées</mark>",
      "city": "Paris 8e Arrondissement",
      "county": "Paris",
      "administrative": "Île-de-France",
      "country": "France",
      "countrycode": "fr",
      "zipcode": [
        "75008"
      ],
      "population": 2220445,
      "lat": 48.871086,
      "lng": 2.3036339,
      "type": "street"
    },
    #...
  [/disclose]],
  "resultsCount": 2,
  "maxResults": 5,
  "query": "42 avenue Champs-Élysées Paris",
[/disclose]}

# Reverse geocoding
curl -l -X POST 'https://api.placekit.co/reverse' \\
  -H 'x-placekit-api-key: <your-api-key>' \\
  -H 'Content-Type: application/json' \\
  -d '{"coordinates": "48.871086,2.3036339", "countries": ["fr"]}'

# JSON response
{[disclose]
  "results": [[disclose]
    {
      "name": "Paris 8e Arrondissement",
      "highlight": "Paris 8e Arrondissement",
      "city": "Paris",
      "county": "Paris",
      "administrative": "Île-de-France",
      "country": "France",
      "countrycode": "fr",
      "zipcode": [
        "75008"
      ],
      "population": 2220445,
      "lat": 48.877762,
      "lng": 2.3175571,
      "type": "city"
    },
    #...
  [/disclose]],
  "resultsCount": 5,
  "maxResults": 5,
[/disclose]}
      `}
    />
  </Code>
);

SnippetsAPI.propTypes = {
  className: PropTypes.string,
};

export default SnippetsAPI;
