import { useCallback, useState } from 'react';

const randInRange = (min, max) => {
  return Math.random() * (max - min) + min;
};

const degToRad = (deg) => deg * (Math.PI / 180);

export const getRadialCoords = (deg, distance) => {
  const rad = degToRad(deg);
  const radius = distance / 2;
  const x = radius * Math.cos(rad);
  const y = radius * Math.sin(rad);
  return {
    deg,
    distance,
    rad,
    radius,
    x,
    y,
    top: `${(0.5 - y) * 100}%`,
    left: `${(0.5 + x) * 100}%`,
  };
};

export const useRandomRadial = (defaultOptions = {}) => {
  const [pos, setPos] = useState(getRadialCoords(0, 0));

  const generate = useCallback(
    (opts) => {
      const options = {
        angleMin: 0,
        angleMax: 360,
        distanceMin: 0,
        distanceMax: 1,
        ...defaultOptions,
        ...opts,
      };

      const angle = randInRange(options.angleMin, options.angleMax);
      const distance = randInRange(options.distanceMin, options.distanceMax);
      setPos(getRadialCoords(angle, distance));
    },
    [defaultOptions],
  );

  return [pos, generate];
};
