import { PlaceKit } from '@placekit/autocomplete-react';
import cx from 'clsx';
import PropTypes from 'prop-types';
import { useCallback, useState } from 'react';

import Icon from '@placekit/uikit/components/Icon';

import { useUIContext } from 'features/UIContext';

const isObject = (v) => typeof v === 'object' && !Array.isArray(v) && v !== null;
const flattenObject = (obj, prefix = '') =>
  Object.entries(obj).reduce((output, [key, val]) => {
    const label = [prefix, key].filter(Boolean).join('.');
    if (isObject(val)) {
      output = { ...output, ...flattenObject(val, label) };
    } else {
      output[label] = Array.isArray(val) ? val.join(', ') : val?.toString() || '(N/A)';
    }
    return output;
  }, {});

const DemoAutocomplete = (props) => {
  const { notify } = useUIContext();
  const [properties, setProperties] = useState();

  const onPick = useCallback((_, item) => setProperties(item ? flattenObject(item) : null), []);

  const onGeolocation = useCallback(
    (value, pos, error) => {
      if (error) {
        notify({
          kind: 'danger',
          icon: 'exclamation-triangle',
          delay: 3000,
          title: 'Geolocation request failed',
          message: error,
        });
      }
    },
    [notify],
  );

  return (
    <div className={props.className}>
      <PlaceKit
        name="address"
        placeholder="Start typing an address..."
        className="w-full [--pka-scale:1.25rem]"
        apiKey={process.env.NEXT_PUBLIC_PLACEKIT_API_KEY}
        options={{
          panel: {
            className: 'sm:[--pka-scale:1.25rem]',
          },
        }}
        onPick={onPick}
        onGeolocation={onGeolocation}
      />
      <aside className="h-48 overflow-auto mt-6 border border-gray-200 rounded-lg bg-white shadow-md shadow-gray-800/5 not-italic">
        {!!properties ? (
          <dl className="flex flex-col text-sm">
            {Object.entries(properties).map(([key, val], i) => (
              <div
                key={key}
                className={cx('sm:grid sm:grid-cols-3 sm:gap-4 py-2 px-4', {
                  'bg-gray-50': i % 2,
                })}
              >
                <dt className="max-sm:text-xs font-medium text-gray-500 font-mono">{key}</dt>
                <dd className="mt-1 text-gray-900 sm:col-span-2 sm:mt-0">{val}</dd>
              </div>
            ))}
          </dl>
        ) : (
          <div
            className="h-full flex flex-col items-center justify-center gap-4 p-4 bg-gray-50"
            role="presentation"
          >
            <Icon name="magnifying-glass-circle" size="large" className="text-gray-300" />
            <p className="max-w-sm font-semibold text-sm text-gray-700 text-center">
              Search for any address in the world to show its details!
            </p>
          </div>
        )}
      </aside>
    </div>
  );
};

DemoAutocomplete.propTypes = {
  className: PropTypes.string,
};

export default DemoAutocomplete;
