import cx from 'clsx';
import PropTypes from 'prop-types';
import { createContext, useContext } from 'react';

import Container from 'components/Container';

const ThemeContext = createContext('white');

const Section = (props) => (
  <section
    className={cx(
      {
        'pt-12 pb-16 md:pb-32': props.paddings === 'first',
        'py-16 md:py-32': props.paddings === 'default',
        'bg-gradient-to-b from-gray-50 to-gray-100': props.theme === 'gradient-light',
        'bg-gradient-to-b from-accent-500 to-accent-600 text-white':
          props.theme === 'gradient-accent',
        'bg-gray-100': props.theme === 'light',
        'bg-gray-900': props.theme === 'dark',
        'bg-accent-500': props.theme === 'accent',
      },
      props.className,
    )}
  >
    <Container className="space-y-12" id={props.id}>
      <ThemeContext.Provider value={props.theme}>{props.children}</ThemeContext.Provider>
    </Container>
  </section>
);

Section.defaultProps = {
  paddings: 'default',
  theme: 'white',
};

Section.propTypes = {
  paddings: PropTypes.oneOf(['default', 'first', 'manual']),
  theme: PropTypes.oneOf(['white', 'light', 'dark', 'accent', 'gradient-light', 'gradient-accent']),
  id: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
};

const Header = (props) => {
  const HeadingComponent = `h${props.level}`;
  const theme = useContext(ThemeContext);
  return (
    <header
      className={cx(
        {
          'mx-auto text-center': props.center,
          'max-w-2xl space-y-4': props.size === 'normal',
          'max-w-2xl space-y-6': props.size === 'medium',
          'max-w-3xl space-y-8': props.size === 'large',
        },
        props.className,
      )}
    >
      <HeadingComponent
        className={cx(
          'inline-flex font-bold',
          {
            'text-3xl sm:text-4xl md:leading-tight tracking-tight': props.size === 'normal',
            'text-4xl md:text-6xl md:leading-tight tracking-wide': props.size === 'medium',
            'text-5xl sm:text-7xl md:leading-tight': props.size === 'large',
          },
          {
            'text-gray-900':
              ['white', 'light'].includes(theme) ||
              (theme === 'gradient-light' && props.size === 'normal'),
            'text-white': ['gradient-accent', 'dark'].includes(theme),
            'bg-gradient-to-r from-gray-800 via-gray-600 to-gray-800 bg-clip-text text-transparent':
              theme === 'gradient-light' && props.size !== 'normal',
          },
        )}
      >
        {props.title}
      </HeadingComponent>
      <div>{props.children}</div>
    </header>
  );
};

Header.defaultProps = {
  level: 2,
  size: 'normal',
  center: false,
};

Header.propTypes = {
  level: PropTypes.oneOf([1, 2, 3]),
  size: PropTypes.oneOf(['normal', 'medium', 'large']),
  center: PropTypes.bool,
  title: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
};

Section.Header = Header;
Section.ThemeContext = ThemeContext;

export default Section;
