import cx from 'clsx';
import PropTypes from 'prop-types';
import { useMemo, useEffect, useState } from 'react';

import Icon from '@placekit/uikit/components/Icon';

import { getRadialCoords } from 'features/useRandomRadial';

const StorePoint = (props) => {
  const pos = useMemo(() => getRadialCoords(props.angle, props.distance), [props]);

  return (
    <span
      className={cx(
        'absolute z-1 flex',
        'rounded-md shadow-lg',
        '-translate-x-1/2 -translate-y-1/2 transform-gpu transition-all',
        {
          'p-3 bg-accent-100 shadow-accent-100/50 text-accent-800': !props.highlight,
          'p-4 bg-accent-500 shadow-accent-100 text-white': props.highlight,
        },
      )}
      style={{
        top: pos.top,
        left: pos.left,
      }}
    >
      <Icon name="shopping-bag" />
    </span>
  );
};

const StoreCard = (props) => (
  <span
    className={cx(
      'flex items-center gap-2 lg:gap-3 px-2 lg:px-3',
      'rounded lg:rounded-lg shadow-lg transform-gpu transition-all',
      {
        'w-24 lg:w-32 h-4 lg:h-6 bg-gray-700 shadow-accent-100/50 text-gray-500': !props.highlight,
        'w-32 lg:w-44 h-8 lg:h-10 bg-accent-500 shadow-accent-100 text-white': props.highlight,
      },
    )}
  >
    <span className="shrink-0 w-1 lg:w-2 h-1 lg:h-2 rounded-full bg-current"></span>
    <div className="relative grow h-1">
      <div
        className={cx(
          'absolute top-0 left-0 z-1 w-12 lg:w-16 h-1 bg-current',
          'transform-gpu transition-transform',
          {
            '-translate-y-1': props.highlight,
          },
        )}
      ></div>
      <div
        className={cx(
          'absolute top-0 left-0 z-0 w-16 lg:w-24 h-1 bg-white',
          'transform-gpu transition-all',
          {
            'opacity-0': !props.highlight,
            'translate-y-1 opacity-50': props.highlight,
          },
        )}
      ></div>
    </div>
  </span>
);

const stores = [
  { angle: 230, distance: 0.35, index: 0 },
  { angle: 60, distance: 0.5, index: 2 },
  { angle: 130, distance: 0.65, index: 1 },
];

const StoreLocatorAnimation = (props) => {
  const [current, setCurrent] = useState(0);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setCurrent((prev) => (prev + 1) % (stores.length + 1));
    }, 3000);
    return () => {
      clearInterval(timeout);
    };
  }, [current]);

  return (
    <div className={props.className} role="img" aria-hidden={true}>
      <div className="relative mx-auto w-full aspect-square select-none pointer-events-none">
        <div className="absolute inset-0 w-full h-full -translate-x-4 lg:translate-x-none">
          <svg
            viewBox="0 0 400 400"
            fill="none"
            strokeLinecap="round"
            className="h-full w-full stroke-1 stroke-gray-300/50"
          >
            <g className="origin-center animate-scan-reverse">
              <circle vectorEffect="non-scaling-stroke" cx="200" cy="200" r="160" />
              <path
                vectorEffect="non-scaling-stroke"
                stroke="url(#locator-1)"
                d="M40 200c0-88.306 71.694-160 160-160"
              />
            </g>
            <g className="origin-center animate-scan animation-delay-[2s]">
              <circle vectorEffect="non-scaling-stroke" cx="200" cy="200" r="160" />
              <path
                vectorEffect="non-scaling-stroke"
                stroke="url(#locator-2)"
                d="M360 200c0-88.306-71.694-160-160-160"
              />
            </g>
            <defs>
              <linearGradient
                id="locator-1"
                x1="0"
                x2="1"
                y1="0"
                y2="0"
                gradientTransform="matrix(160 0 0 160 40 120)"
                gradientUnits="userSpaceOnUse"
              >
                <stop offset="0" stopColor="#7cadfd" />
                <stop offset="1" stopColor="#7cadfd" stopOpacity="0" />
              </linearGradient>
              <linearGradient
                id="locator-2"
                x1="0"
                x2="1"
                y1="0"
                y2="0"
                gradientTransform="matrix(-160 0 0 160 360 120)"
                gradientUnits="userSpaceOnUse"
              >
                <stop offset="0" stopColor="#7cadfd" />
                <stop offset="1" stopColor="#7cadfd" stopOpacity="0" />
              </linearGradient>
            </defs>
          </svg>
          <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
            <Icon name="map-pin" size="large" className="text-accent-500 animate-bounce" />
          </div>
          <div className="absolute inset-0">
            {stores.map((store) => (
              <StorePoint
                key={store.index.toString()}
                angle={store.angle}
                distance={store.distance}
                highlight={store.index === current}
              />
            ))}
          </div>
        </div>
        <div className="absolute z-2 bottom-1/4 right-4 flex flex-col items-end gap-y-4">
          {stores.map((store) => (
            <StoreCard key={store.index.toString()} highlight={store.index === current} />
          ))}
        </div>
      </div>
    </div>
  );
};

StoreLocatorAnimation.propTypes = {
  className: PropTypes.string,
};

export default StoreLocatorAnimation;
