import cx from 'clsx';
import numeral from 'numeral';
import PropTypes from 'prop-types';
import { useState } from 'react';

import pricing from '@placekit/pricing';
import Icon from '@placekit/uikit/components/Icon';
import Link from '@placekit/uikit/components/Link';
import Range from '@placekit/uikit/components/Range';

const [startValue, endValue] = [10000, 1000000];
const actualValues = [startValue];
while (actualValues.slice(-1) <= endValue) {
  const prev = actualValues.slice(-1);
  const val = numeral(prev)
    .add(prev < 100000 ? 10000 : 50000)
    .value();
  actualValues.push(val);
}
const maxValue = actualValues.length - 1;

const Tier = (props) => (
  <button
    onClick={props.onClick}
    role="option"
    aria-disabled={props.value < props.min}
    aria-selected={props.value >= props.min}
    className={cx(
      'appearance-none flex gap-2 items-center text-left',
      'p-2 rounded-md',
      'transition-colors ease-out duration-150',
      {
        'bg-gray-50 text-gray-400': props.value < props.min,
        'bg-accent-50 text-gray-600': props.value >= props.min,
      },
    )}
  >
    <span
      aria-hidden="true"
      className={cx(
        'shrink-0 flex items-center justify-center',
        'w-12 h-12',
        'transition-colors ease-out duration-150',
        {
          'text-gray-300': props.value < props.min,
          'text-accent-600': props.value >= props.min,
        },
      )}
    >
      <Icon name={props.value < props.min ? 'lock-closed' : 'check-circle'} size="medium" />
    </span>
    <span className="flex flex-col">
      <span
        className={cx({
          'font-semibold': props.value >= props.min,
        })}
      >
        {numeral(props.min).format('0a')}
        {props.max ? ` - ${numeral(props.max).format('0a')}` : '+'}
      </span>
      <span>
        <strong
          className={cx(
            'font-mono font-medium text-bas',
            'transition-colors ease-out duration-150',
            {
              'text-gray-500': props.value < props.min,
              'text-accent-600': props.value >= props.min,
            },
          )}
        >
          {numeral(props.price).format('$0.0000')}
        </strong>
        {' /req./mo.'}
      </span>
    </span>
  </button>
);

Tier.propTypes = {
  value: PropTypes.number.isRequired,
  min: PropTypes.number.isRequired,
  max: PropTypes.number,
  price: PropTypes.number.isRequired,
  onClick: PropTypes.func,
};

const Pricing = (props) => {
  const [value, setValue] = useState(0);

  const nbRequests = actualValues[Math.max(0, Math.min(maxValue, Math.round(value)))];
  const amount = pricing.compute(nbRequests, 10000);

  return (
    <div className={cx('p-4 sm:p-8 rounded-md bg-white text-gray-900', props.className)}>
      <div className="flex items-center justify-between gap-2 sm:gap-4 font-medium text-md lg:text-xl text-gray-500 leading-6">
        <p className="flex-1 text-right">
          <strong className="block sm:inline font-mono font-semibold text-lg md:text-2xl text-gray-800">
            {value < maxValue ? numeral(nbRequests).format('0a') : numeral(endValue).format('0a+')}
          </strong>
          <span className="whitespace-nowrap"> requests/month</span>
        </p>
        <Icon name="arrows-right-left" size="small" className="flex-none text-gray-300" />
        <div className="flex-1 text-left">
          {value < maxValue ? (
            <p>
              <strong className="block sm:inline font-mono font-semibold text-lg md:text-2xl text-accent-500">
                {numeral(amount).format('$0,0')}
              </strong>
              <span className="whitespace-nowrap"> /month</span>
            </p>
          ) : (
            <Link
              href="/about#contact"
              scroll={false}
              className="font-semibold text-accent-500 hover:text-accent-600 focus:text-accent-600 underline underline-offset-2 decoration-2 decoration-accent-100 hover:decoration-accent-200 focus:decoration-accent-200"
            >
              Contact us
            </Link>
          )}
        </div>
      </div>
      <div className="mt-4">
        <Range
          label="Volume of requests"
          name="volume"
          min={0}
          max={maxValue}
          step={1}
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />
      </div>
      <div
        role="listbox"
        aria-multiselectable="true"
        aria-label="Volume requests"
        className="grid sm:grid-cols-2 lg:grid-cols-4 gap-2 sm:gap-4 xl:gap-8 mt-6 text-sm"
      >
        {pricing.tiers.map((tier, i) => {
          const upTier = pricing.tiers[i + 1];
          return tier.from >= endValue ? null : (
            <Tier
              key={`pricing-tier-${i}`}
              min={tier.from}
              max={upTier?.from}
              value={nbRequests}
              price={tier.price}
              onClick={() => setValue(actualValues.indexOf(tier.from))}
            />
          );
        })}
      </div>
    </div>
  );
};

Pricing.propTypes = {
  /** Optional container class name */
  className: PropTypes.string,
};

export default Pricing;
