import Button from '@placekit/uikit/components/Button';

import Features from 'components/Features';
import HeroAnimation from 'components/HeroAnimation';
import HeroDemos from 'components/HeroDemos';
import Pricing from 'components/Pricing';
import Section from 'components/Section';

const HomePage = () => (
  <>
    <Section theme="gradient-light" className="relative border-b border-gray-200 overflow-hidden">
      <div className="relative z-2 text-center">
        <Section.Header
          level={1}
          size="large"
          center={true}
          title="Locations search API"
          className="sm:max-md:mb-6"
        >
          <p className="mx-auto mt-8 max-w-lg text-lg font-medium bg-gradient-to-r from-gray-700 via-gray-500 to-gray-700 bg-clip-text text-transparent">
            Worldwide places search, address autofill, store locator, and two-way geocoding for your
            apps.
          </p>
          <div className="mt-16 flex max-sm:flex-col items-center justify-center gap-4 sm:gap-6">
            <Button
              href="https://app.placekit.io/auth/register"
              label="Get started for free"
              kind="primary"
              size="medium"
            />
            <Button
              href="/#overview"
              scroll={false}
              label="Discover features"
              kind="neutral"
              size="medium"
              icon="sparkles"
              iconKind="outline"
            />
          </div>
        </Section.Header>
      </div>
      <HeroAnimation className="absolute left-1/2 top-20 sm:-top-12 w-[1058px] -translate-x-1/2" />
    </Section>
    <HeroDemos className="bg-accent-500" />
    <Features className="sm:-mt-8 md:-mt-16" />
    <Pricing />
  </>
);

export const getStaticProps = () => ({
  props: {
    meta: {
      title: 'Geocoding API and autocomplete',
      description:
        'PlaceKit is a worldwide geocoding API providing fast and accurate address autocomplete, store locator, and two-way geocoding for your app.',
    },
  },
});

export default HomePage;
