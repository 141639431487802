import axios from 'axios';
import cx from 'clsx';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useCallback, useState } from 'react';
import validator from 'validator';

import { useUIContext } from 'features/UIContext';
import ActionGroup from '@placekit/uikit/components/ActionGroup';
import Button from '@placekit/uikit/components/Button';
import Form from '@placekit/uikit/components/Form';
import FormField from '@placekit/uikit/components/FormField';
import Icon from '@placekit/uikit/components/Icon';
import Input from '@placekit/uikit/components/Input';
import Spinner from '@placekit/uikit/components/Spinner';

const Subscribe = (props) => {
  const { notify } = useUIContext();
  const [loading, setLoading] = useState(false);

  const methods = useForm({
    mode: 'onSubmit',
    reValidateMode: 'onSubmit',
  });

  const onSubmit = useCallback(
    (values) => {
      setLoading(true);
      axios
        .post(
          process.env.NEXT_PUBLIC_SUBSCRIBE_ENDPOINT,
          {
            ...values,
            list: props.list,
          },
          {
            headers: {
              'x-token': `${process.env.NEXT_PUBLIC_SUBSCRIBE_TOKEN}`,
            },
          },
        )
        .then(() => {
          notify({
            kind: 'success',
            icon: 'check-badge',
            delay: 6000,
            title: `Subscribed!`,
            message: 'You will receive an email when we release the Store Locator feature.',
          });
          methods.reset();
        })
        .catch((err) => {
          notify({
            kind: 'danger',
            icon: 'exclamation-triangle',
            delay: 6000,
            title: 'Oops, something went wrong!',
            // message: `Please try again, and contact us if the error persists.`,
            message: err.message,
          });
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [methods, notify, props],
  );

  return (
    <Form
      methods={methods}
      onSubmit={onSubmit}
      id={`${props.list}-subscribe-form`}
      className={cx(
        'inline-flex flex-col gap-2 p-4 rounded-lg border border-gray-200 bg-white shadow-md shadow-gray-800/5',
        props.className,
      )}
    >
      <div className="flex items-center gap-3 rounded-lg bg-accent-50 p-1 pr-4">
        <span
          className="inline-flex p-2 rounded-md bg-accent-100 text-accent-700"
          aria-hidden="true"
        >
          <Icon name="bell" />
        </span>
        <h4 className="leading-none font-semibold text-accent-600 text-md text-left">
          {props.title || 'Subscribe!'}
        </h4>
      </div>
      <FormField
        name="name"
        label="Name"
        discrete={true}
        autoComplete="off"
        tabIndex="-1"
        render={({ errored, ...fieldProps }) => <Input {...fieldProps} className="!hidden" />}
        className="col-span-full h-0 overflow-hidden"
      />
      <ActionGroup stack={false} fullWidth={true}>
        <FormField
          name="email"
          label="Email"
          type="email"
          discrete={true}
          placeholder="Enter your email..."
          rules={{
            validate: (val) => validator.isEmail(val),
          }}
          icon="at-symbol"
          render={(fieldProps) => <Input {...fieldProps} />}
        />
        <Button type="submit" kind="primary" disabled={loading}>
          {!loading ? 'Send' : <Spinner />}
        </Button>
      </ActionGroup>
    </Form>
  );
};

Subscribe.propTypes = {
  title: PropTypes.string,
  list: PropTypes.string.isRequired,
};

export default Subscribe;
